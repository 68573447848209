<template>
  <div id="reg">
    <h4>You shall not provide lessons that might fall under any of the following items:</h4>
    <ul>
      <li>lessons that infringe or encourage infringement of intellectual property rights, copyrights, etc.;</li>
      <li>lessons that are against the law;</li>
      <li>lessons that are offensive to public order and morals;</li>
      <li>lessons that require or encourage the participant to bear any financial burden directly or indirectly;</li>
      <li>lessons that use copyrighted material of the third party other than the user;</li>
      <li>lessons that require a license or special skills to administer; and</li>
      <li>lessons that are determined to have a risk of injuring another user or participant;</li>
    </ul>
    <div class="note mt-2">
      <b>
        Enforcement Date of First Edition (Date of Last Update): <i>February 27th, 2022</i>
      </b>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
ul {
  list-style-type: lower-roman;
  li {
  margin: 1.5vw 0;
  }
}
</style>
