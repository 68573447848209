<template>
  <div id="reg">
    <h4>Cư dân không được giảng dạy các bài học có thể thuộc một trong các mục dưới đây:</h4>
    <ul>
      <li>các bài học vi phạm hoặc khuyến khích việc vi phạm quyền sở hữu trí tuệ, bản quyền, v.v;</li>
      <li>các bài học vi phạm pháp luật;</li>
      <li>các bài học gây mất trật tự công cộng và trái với đạo đức;</li>
      <li>các bài học yêu cầu hoặc khuyến khích người tham gia trực tiếp hoặc gián tiếp chịu bất kỳ gánh nặng tài chính nào;</li>
      <li>các bài học sử dụng tài liệu có bản quyền của bên thứ ba không phải người sử dụng;</li>
      <li>các bài học yêu cầu giấy phép hoặc các kỹ năng đặc biệt để thực hiện; và</li>
      <li> các bài học được xác định là có rủi ro gây thương tích cho người sử dụng khác hoặc người tham gia;</li>
    </ul>
    <div class="note mt-2">
      <b>Ngày Thi Hành Phiên Bản Đầu Tiên (Ngày Cập Nhật Cuối Cùng): <i>Ngày 27 tháng 02 năm 2022</i>.</b>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
ul {
  list-style-type: lower-roman;
  li {
  margin: 1vw 0vw;
  }
}
</style>
