<template>
  <div>
    <v-card>
      <v-form
        ref="topicInformationForm"
        @submit.prevent="handleTopicInformationFormSubmit"
      >
        <v-card-title>
          {{ $t('RegisterSkillShare') }}
        </v-card-title>
        <v-card-text>
          <strong>{{ $t('Time') }}</strong>: {{ enrichTopicTime(
            formatTimestampToDatetime(topicData.start_time, 'DD/MM/YYYY HH:mm'),
            formatTimestampToDatetime(topicData.end_time, 'DD/MM/YYYY HH:mm')
          ) }}
        </v-card-text>
        <v-card-text>
          <strong>{{ $t('Speaker') }}</strong>: {{ topicData.speaker }}
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="topicName"
            dense
            outlined
            :label="$t('Topic')"
            :placeholder="$t('Topic')"
            :rules="[validators.required]"
            hide-details="auto"
            validate-on-blur
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="maxSeat"
            dense
            outlined
            type="number"
            :label="$t('ExpectedParticipants')"
            :placeholder="$t('ExpectedParticipants')"
            :rules="[validators.required]"
            hide-details="auto"
            validate-on-blur
          />
        </v-card-text>

        <v-card-text>
          <v-textarea
            v-model="description"
            dense
            outlined
            :label="$t('Description')"
            :placeholder="$t('Description')"
            :rules="[validators.required]"
            hide-details="auto"
            validate-on-blur
          ></v-textarea>
        </v-card-text>
        <v-card-text>
          <v-row v-if="topicData.id_topic">
            <v-col
              cols="6"
              class="text-center"
            >
              <Upload
                v-model="imageUpload1"
                :auto-sizing="true"
                :initial-image="topicData.image_1_url"
              />
              <div>
                {{ $t('Image') }} 1
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <Upload
                v-model="imageUpload2"
                :auto-sizing="true"
                :initial-image="topicData.image_2_url"
              />
              <div>
                {{ $t('Image') }} 2
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-btn
            color="primary"
            block
            type="submit"
            :loading="loading"
          >
            {{ $t('Save') }}
          </v-btn>

          <v-btn
            class="mt-4"
            outlined
            block
            @click="$router.back()"
          >
            {{ $t('Back') }}
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { required } from '@core/utils/validation'
import { ref, onMounted } from '@vue/composition-api'
import Upload from '@/components/Upload/Upload.vue'
import store from '@/store'
import UploadService from '@/services/UploadService'
import SkillShareService from '@/services/SkillShareService'
import { useRouter } from '@core/utils'
import { enrichTopicTime } from '@/utils/skillShareHelper'

import Reg from '@/views/SkillShare/User/components/Regulations.vue'
import RegEn from '@/views/SkillShare/User/components/Regulations-en.vue'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default {
  components: { Upload },
  computed: {
    reg() {
      return this.$i18n.locale === 'en' ? RegEn : Reg
    },
  },
  setup() {
    const { router } = useRouter()
    const topicId = router.currentRoute.params.id
    const topicInformationForm = ref(null)
    const maxSeat = ref(null)
    const topicName = ref('')
    const description = ref('')
    const imageUpload1 = ref({})
    const image1 = ref(null)
    const imageUpload2 = ref({})
    const image2 = ref(null)
    const loading = ref(false)
    const topicData = ref({})

    const getTopicData = async () => {
      try {
        const resp = await SkillShareService.getTopicDetail(topicId)
        topicData.value = resp.data.data.topic
        topicName.value = topicData.value.name
        maxSeat.value = topicData.value.max_seat
        description.value = topicData.value.description
      } catch {}
    }

    onMounted(async () => {
      await getTopicData()
    })

    const handleTopicInformationFormSubmit = async () => {
      const isFormValid = topicInformationForm.value.validate()

      if (isFormValid) {
        loading.value = true
        try {
          if (imageUpload1.value.chosenFile) {
            await imageUpload1.value
              .asyncBase64('image/jpeg', 0.8)
              .then(async base64 => {
                await UploadService.uploadFile({
                  note: 'imageUpload1',
                  files: [base64],
                }).then(res => {
                  const imageUpload1Id = res.data?.data?.files?.[0]?.id_file
                  image1.value = imageUpload1Id
                })
              })
              .catch(error => {
                console.error(error)
                store.commit('snackbar/show', {
                  message: 'PleaseUpload',
                  type: 'error',
                })
              })
          }

          if (imageUpload2.value.chosenFile) {
            await imageUpload2.value
              .asyncBase64('image/jpeg', 0.8)
              .then(async base64 => {
                await UploadService.uploadFile({
                  note: 'imageUpload2',
                  files: [base64],
                }).then(res => {
                  const imageUpload2Id = res.data?.data?.files?.[0]?.id_file
                  image2.value = imageUpload2Id
                })
              })
              .catch(error => {
                console.error(error)
                store.commit('snackbar/show', {
                  message: 'PleaseUpload',
                  type: 'error',
                })
              })
          }

          const newTopicData = {
            name: topicName.value,
            max_seat: maxSeat.value,
            description: description.value,
            image_1: image1.value,
            image_2: image2.value,
          }

          await SkillShareService.editTopic(topicData.value.id_topic, newTopicData)
          store.commit('snackbar/show', {
            message: 'Update event successfully',
            type: 'success',
          })
          router.push({ name: 'staff-skill-share-list' })
        } catch (e) {
        } finally {
          loading.value = false
        }
      }
    }

    return {
      maxSeat,
      topicName,
      description,
      imageUpload1,
      imageUpload2,
      loading,
      topicData,
      enrichTopicTime,

      validators: {
        required,
      },

      topicInformationForm,
      handleTopicInformationFormSubmit,
    }
  },
}
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
  border: 1px solid #ccc;
  display: block !important;
  margin: 0 auto;
}
</style>
